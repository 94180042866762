<template>
  <footer class="footer">
    <div class="icon-slider" @wheel="onScroll">
      <i v-for="(icon, index) in orderedIcons" :key="index"
         :class="['fas', icon.class, { 'active-icon': activeIcon === icon.name, 'home-icon': activeIcon === 'home' && icon.name === 'home' }]"
         @click="navigateTo(icon.link)"
         :style="iconStyles(icon.name)">
        <!-- Badge de notifications non lues -->
        <span v-if="icon.name === 'notifications' && newNotifications > 0" :class="['notification-badge', { 'small-badge': activeIcon !== 'notifications' }]">
          {{ newNotifications }}
        </span>
      </i>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      activeIcon: 'home', // Icône active initiale
      icons: [
        { name: 'home', class: 'fa-home', link: '/home' },
        { name: 'about', class: 'fa-info-circle', link: '/about' },
        { name: 'rates', class: 'fa-chart-line', link: '/rates' },
        { name: 'contact', class: 'fa-envelope', link: '/contact-page' },
        { name: 'profile', class: 'fa-user', link: '/profile' },
        { name: 'settings', class: 'fa-cog', link: '/settings' },
        { name: 'notifications', class: 'fa-bell', link: '/notifications' },
        { name: 'history', class: 'fa-history', link: '/history' },
      ],
      newNotifications: 0, // Nombre de notifications non lues
    };
  },
  computed: {
    orderedIcons() {
      const activeIndex = this.icons.findIndex(icon => icon.name === this.activeIcon);
      if (activeIndex === -1) return this.icons;

      const newPosition = 3; // Position cible de l'icône active (index 3 = 4e place)

      // Retirer l'icône active du tableau
      const reorderedIcons = [...this.icons];
      const [activeIcon] = reorderedIcons.splice(activeIndex, 1);

      // Insérer l'icône active à la position 3
      reorderedIcons.splice(newPosition, 0, activeIcon);

      return reorderedIcons;
    }
  },
  watch: {
    $route(to) {
      this.setActiveIconFromRoute(to.path);
    }
  },
  created() {
    this.updateNotificationCount();
    this.setActiveIconFromRoute(this.$route.path);
  },
  methods: {
    updateNotificationCount() {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const decodedToken = JSON.parse(jsonPayload);
        this.newNotifications = decodedToken.newNotifications || 0;
      } catch (error) {
        console.error('Erreur lors de la décomposition du token:', error);
        this.newNotifications = 0;
      }
    },
    setActiveIcon(icon) {
      this.activeIcon = icon;
    },
    setActiveIconFromRoute(path) {
      const icon = this.icons.find(icon => icon.link === path);
      if (icon) {
        this.setActiveIcon(icon.name);
      } else {
        this.setActiveIcon('home');
      }
    },
    navigateTo(link) {
      this.$router.push(link);
    },
    onScroll(event) {
      const delta = Math.sign(event.deltaY);
      if (delta > 0) {
        this.iconIndex = (this.iconIndex + 1) % this.icons.length;
      } else {
        this.iconIndex = (this.iconIndex - 1 + this.icons.length) % this.icons.length;
      }
    },
    iconStyles(name) {
      return {
        color: this.activeIcon === name ? '#000103' : '#a0a0a0',
        backgroundColor: this.activeIcon === name ? '#ffffff' : 'transparent',
        borderRadius: this.activeIcon === name ? '50%' : '0%',
        padding: this.activeIcon === name ? '8px' : '0',
        margin: this.activeIcon === name ? '5px' : '0',
        transform: this.activeIcon === name ? 'scale(1)' : 'scale(1)',
      };
    }
  },
};
</script>


<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000103;
  border-top: 3px solid #016A40;
  border-bottom: 3px solid #016A40;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  height: 35px;
}

.icon-slider {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
}

.icon-slider::-webkit-scrollbar {
  display: none;
}

.icon-slider i {
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s, color 0.3s, background-color 0.3s;
  margin: 0 10px;
  position: relative;
}

.icon-slider i.active-icon {
  transform: scale(1.2);
}

.icon-slider i.home-icon {
  color: #016A40;
  background-color: transparent;
  border-radius: 50%;
  padding: 5px;
  font-size: 1rem;
  margin: 5px;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #FF0000;
  color: #FFFFFF;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: width 0.3s, height 0.3s, font-size 0.3s;
}

.notification-badge.small-badge {
  width: 15px;
  height: 15px;
  font-size: 10px;
}
</style>
