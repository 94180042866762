<template>
    <h1>
      <i class="fas fa-arrow-left icon-back" @click="goBack"></i>
      {{ title }}
    </h1>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1); // Retourne à la page précédente
      }
    }
  };
  </script>
  
  <style scoped>
    h1 {
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon-back {
        background-color: white;
        border-radius: 50%;
        padding: 8px;
        margin-right: 50px;
        color: black;
        font-size: 0.5em;
        cursor: pointer;
    }
  </style>
  