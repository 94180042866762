// utils/translator.js
import { createI18n } from 'vue-i18n';

// Définir les messages de traduction
const messages = {
    locale: 'fr',
    fallbackLocale: 'fr',

    en: { 
        pageLogin: {
            pageTitle: 'Access Your Account',
            phoneOrEmail: 'Phone number or email address',
            password: 'Password',
            stayConnected: 'Keep me signed in',
            forgotPassword: 'Forgot password?',
            connecting: 'Please wait, signing in..',
            connect: 'Log in',
            createAccount: 'Sign up',
            noAccount: 'Don’t have an account?',
            noAccountFound: 'No account found for this phone number or email.',
            invalidPassword: 'Incorrect password.',
            accountInactive: 'Your account is not yet activated. Please check your email to activate it.',
            enterPhone: 'Please enter your phone number or email.',
            enterValidPhone: 'Please enter a valid email or phone number.',
            enterPassword: 'Please enter your password.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Forgot Password',
            phoneOrEmail: 'Phone number or email address',
            loading: 'Loading...',
            reset: 'Reset',
            accueil: 'Home page',
            linkSent: 'A reset link has been sent. Please check your email.',
            errorRegistered: 'An error occurred during the reset request.',
        },

        message: { 
            hello: 'Hello World',
            stayConnected: 'Stay connected',
            fullName: 'Full Name',
            email: 'Email',
            phoneNumber: 'Phone Number',
            address: 'Address',
            dateOfBirth: 'Date of Birth',
            gender: 'Gender',
            personalInfo: 'Personal Information',
            securityPrivacy: 'Security and Privacy',
            preferences: 'Preferences',
            activityHistory: 'Activity History',
            accountManagement: 'Account Management',
            supportAssistance: 'Support and Assistance',
        },
        pageTitle: { 
            profile: 'My Profile',
        },

        pageBasicTransfert: { 
            title: 'Perform a Transfer',
            sumbit: 'Submit',
        },
        pageBasicWithdraw:{
            title: 'Withdraw Funds',
        },
        pageBasicDeposit:{
            title: 'Deposit',
        },
        pageConfirmWithdraw:{
            title: 'Authorize Withdrawal',
        },
        pageConfirmDeposit:{
            title: 'Deposit Confirmation',
        },
        pageTracking:{
            title: 'Transaction Tracking',
        },

        pageNotifications: { 
            title: 'Notifications',
        },
        CreatePin: { 
            title: 'Create your code PIN',
        },
        
    },

    fr: { 
        pageLogin: {
            pageTitle: 'Se connecter',
            phoneOrEmail: 'Numéro de téléphone ou adresse email',
            password: 'Mot de passe',
            stayConnected: 'Restez connecté',
            forgotPassword: 'Mot de passe oublié ?',
            connecting: 'Connexion en cours...',
            connect: 'Se connecter',
            createAccount: 'Kreye yon kont',
            noAccount: 'Vous n\'avez pas de compte ?',
            noAccountFound: 'Aucun compte associé à ce nom numéro de téléphone ou email.',
            invalidPassword: 'Le mot de passe est incorrect.',
            accountInactive: 'Votre compte n\'est pas encore activé. Veuillez vérifier votre email pour l\'activer.',
            enterPhone: 'Veuillez entrer votre No de téléphone ou email.',
            enterValidPhone: 'Veuillez entrer un email valide ou un numéro de téléphone valide.',
            enterPassword: 'Veuillez entrer votre mot de passe.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Mot de passe oublié',
            phoneOrEmail: 'Numéro de téléphone ou adresse email',
            loading: 'En cours de chargement...',
            reset: 'Réinitialiser',
            accueil: 'Accueil',
            linkSent: 'Un lien de réinitialisation a été envoyé. Veuillez consulter votre email.',
            errorRegistered: 'Une erreur est survenue pendant la demande de réinitialisation.',
        },

        message: { 
            hello: 'Bonjour le monde',
            stayConnected: 'Rester connecté',
            fullName: 'Nom Complet',
            email: 'Email',
            phoneNumber: 'No de Téléphone',
            address: 'Adresse',
            dateOfBirth: 'Date de Naissance',
            gender: 'Genre',
            personalInfo: 'Informations Personnelles',
            securityPrivacy: 'Sécurité et Confidentialité',
            preferences: 'Préférences',
            activityHistory: 'Historique des Activités',
            accountManagement: 'Gestion de Compte',
            supportAssistance: 'Support et Assistance',
        },
        pageTitle: {
            profile: 'Mon Profil',
        },
        pageBasicTransfert: { 
            title: 'Effectuer un Transfert',
            sumbit: 'Soumettre',
        },
        pageBasicWithdraw:{
            title: 'Retirer des Fonds',
        },
        pageConfirmWithdraw:{
            title: 'Autorisation de Retrait',
        },
        pageBasicDeposit:{
            title: 'Dépôt',
        },
        pageConfirmDeposit:{
            title: 'Validation de Dépôt',
        },
        pageTracking:{
            title: 'Suivi de Transaction',
        },
        pageNotifications: { 
            title: 'Notifications',
        },
        CreatePin: { 
            title: 'Créer votre code PIN',
        },
    },

    ht: {
        pageLogin: {
            pageTitle: 'Antre nan kont ou',
            phoneOrEmail: 'Nimewo telefòn oswa adrès imèl',
            password: 'Modpas',
            stayConnected: 'Rete konekte',
            forgotPassword: 'Ou bliye modpas ou?',
            connecting: 'Tanpri tann, ap konekte...',
            connect: 'Konekte w',
            createAccount: 'Kreye yon kont',
            noAccount: 'Ou poko gen kont?',
            noAccountFound: 'Pa gen kont ki koresponn ak non itilizatè oswa imèl sa a.',
            invalidPassword: 'Modpas sa a pa kòrèk.',
            accountInactive: 'Kont ou poko aktive. Tanpri tcheke imèl ou pou aktive l.',
            enterPhone: 'Tanpri antre nimewo telefòn ou oswa imèl ou.',
            enterValidPhone: 'Tanpri antre yon imèl oswa yon nimewo telefòn ki valab.',
            enterPassword: 'Tanpri antre modpas ou.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Bliye modpas',
            phoneOrEmail: 'Nimewo telefòn oswa adrès imèl',
            loading: 'Chajman ap fèt...',
            reset: 'Reyajiste',
            accueil: 'Paj dakèy',
            linkSent: 'Yon lyen pou reyajiste modpas al jwenn ou. Tanpri verifye imèl ou.',
            errorRegistered: 'Yon erè rive pandan demann reyajisteman an.',
        },

        message: { 
            fullName: 'Non Konplè',
            email: 'Imèl',
            phoneNumber: 'Nimewo Telefòn',
            address: 'Adrès',
            dateOfBirth: 'Dat Nesans',
            gender: 'Sèks',
            personalInfo: 'Enfòmasyon Pèsonèl',
            securityPrivacy: 'Sekirite ak Konfidansyalite',
            preferences: 'Preferans',
            activityHistory: 'Istwa Aktivite',
            accountManagement: 'Jesyon Kont',
            supportAssistance: 'Sipò ak Asistans',
        },
        pageTitle: {
            profile: 'Pwofil Mwen',
        },
        pageBasicTransfert: { 
            title: 'Fè yon Transfè',
            sumbit: 'Soumèt',
        },
        pageBasicWithdraw:{
            title: 'Retire Lajan',
        },
        pageConfirmWithdraw:{
            title: 'Otorize Retrè',
        },
        pageBasicDeposit:{
            title: 'Mete lajan sou kont ou',
        },
        pageConfirmDeposit:{
            title: 'Konfime Depo',
        },
        pageNotifications: { 
            title: 'Notifikasyon yo',
        },
        pageTracking:{
            title: 'Swivi Tranzaksyon',
        },
        CreatePin: { 
            title: 'Kreye Kòd PIN ou',
        },
    }
};


// Créer une instance i18n
const i18n = createI18n({
    locale: 'fr', // Langue par défaut
    messages,
});

export default i18n;
