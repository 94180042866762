<template>
    <Header />
    <PageHeader title="Réglages Utilisateur" />

    <div class="preferences-section">
        
        <img src="@/assets/images/ewallet_24.png" alt="Mockup d'interface de réglages" class="mockup-image">
  
      
        <form @submit.prevent="handleSubmit"  v-if="activeIndex === 0" novalidate>
            <!-- Choix de la langue -->
            <div class="form-group">
                <label for="language">
                    <i class="fas fa-globe icon-bg"></i>
                    Langue
                </label>
                <select id="language" v-model="preferences.language" @change="resetError">
                    <option value="en">Anglais</option>
                    <option value="fr">Français</option>
                    <option value="ht">Créole</option>
                </select>
            </div>

            <!-- Choix des notifications -->
            <div class="form-group">
                <label>
                    <i class="fas fa-bell icon-bg"></i>
                    Notifications
                </label>
                <div class="notification-container">
                    <div class="notification-option">
                        <input type="checkbox" id="email" v-model="preferences.email_notifications" @change="resetError">
                        <label for="email">Email</label>
                    </div>
                    <div class="notification-option">
                        <input type="checkbox" id="sms" v-model="preferences.sms_notifications" @change="resetError">
                        <label for="sms">SMS</label>
                    </div>
                    <div class="notification-option">
                        <input type="checkbox" id="app" v-model="preferences.app_notifications" @change="resetError">
                        <label for="app">Web</label>
                    </div>
                </div>
            </div>

            <!-- Abonnement newsletter -->
            <div class="form-group">
                <label>
                    <i class="fa-solid fa-envelopes-bulk icon-bg"></i>
                    Newsletter
                </label>
                <div class="newsletter-container">
                    <div class="newsletter-option">
                    <input type="checkbox" id="newsletter" v-model="preferences.newsletter_subscription" @change="resetError">
                    <label for="newsletter">{{ preferences.newsletter_subscription ? 'Abonné' : 'Désabonné' }}</label>
                    </div>
                </div>
            </div>

            <button type="submit" class="btn-submit" :disabled="loading">
                <span v-if="loading">
                    Sauvegarde en cours <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else>
                    Sauvegarder <i class="fas fa-check-circle"></i>
                </span>
            </button>

            <div class="form-group">
                <span v-if="requestError" class="error-message">{{ requestError }}</span>
            </div>

        </form>

        <div v-if="activeIndex === 1">
          <p>Vos préférences ont été mises à jour avec succès ! Vous pouvez désormais profiter de votre expérience personnalisée.</p>
          <button @click="goBack" class="btn-submit">
            <i class="fas fa-arrow-circle-left"></i> Retour 
          </button>
        </div>
  
    </div>

    <Footer />
</template>
  
<script>
    import Header from './shared/HeaderPage.vue';
    import Footer from './shared/FooterPage.vue';
    import PageHeader from './shared/PageHeader.vue';

    import axios from 'axios';
    import { SERVER_IP } from '../config';
    import { checkAuth } from '@/utils/validationUtils';

  export default {
    name: 'UserPreferences',
    components: {
        Header,
        PageHeader,
        Footer
    },
    
    created(){
        checkAuth();
    },

    mounted() {
        this.fetchPreferences();
    },

    data() {
        return {
            preferences: {
                language: 'fr',
                email_notifications: true,
                sms_notifications: false,
                app_notifications: true,
                newsletter_subscription: true,
            },
            loading: false,
            requestError: "",
            activeIndex: 0,
        };
    },
  
  methods: {

    resetError() {
        this.requestError = "";
    },
    goBack(){
        window.history.back();
    },

    async fetchPreferences() {
      try {
        const userId = checkAuth();
        const response = await axios.get(`${SERVER_IP}/userRequest/get-preferences/${userId}`);
        this.preferences = response.data;
        this.preferences.email_notifications = Boolean(response.data.email_notifications);
        this.preferences.sms_notifications = Boolean(response.data.sms_notifications);
        this.preferences.app_notifications = Boolean(response.data.app_notifications);
        this.preferences.newsletter_subscription = Boolean(response.data.newsletter_subscription);

      } catch (error) {
        console.error('Error fetching preferences:', error);
      }
    },

    async handleSubmit(){
        this.loading = true; // Début du chargement
        const url = `${SERVER_IP}/userRequest/update-preferences`;
        try {
            const userId = checkAuth();
            const response = await axios.post(url, { 
                userId: userId,
                language: this.preferences.language, 
                email_notifications: this.preferences.email_notifications, 
                sms_notifications: this.preferences.sms_notifications, 
                app_notifications: this.preferences.app_notifications, 
                newsletter_subscription: this.preferences.newsletter_subscription 
            });

            if (response.data.success){
                this.$i18n.locale = this.preferences.language;
                this.activeIndex = 1;
            }
            
        } catch (error) {
            this.loading = false;
            this.requestError = "Une erreur est survenue lors du traitement.";
        }
        finally {
            this.loading = false;
        }

    },

    

  },
  

};
</script>


<style scoped>
    .preferences-section {
        padding: 0px 20px;
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
        color: #FFFFFF;
        margin-bottom:80px;
    }

    .mockup-image {
        max-width: 100px;
        margin: 20px auto;
        display: block;
    }

    .icon-bg {
        background-color: #016A40;
        color: white;
        padding: 5px; /* Taille des icônes réduite */
        border-radius: 8px; /* Coins arrondis */
        margin-right: 10px;
        font-size: 0.6em; /* Réduire la taille des icônes */
        width: 15px;
        height: 15px;
        text-align: center;
        font-size: 0.9em;
    }

    .form-group {
        margin-bottom: 15px;
        text-align: left;
    }

    .form-group i{
        margin-right: 10px;
    }

    .form-group label {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .form-group select {
        width: 95%;
        padding: 8px;
        font-size: 1em;
        border: none;
        border-bottom: 2px solid white;
        background-color: transparent;
        transition: border-color 0.3s;
        color: #FFFFFF;
    }

    .form-group select:focus {
        border-color: #016A40;
        outline: none;
    }
    .form-group select option {
        color: #000;
    }

    .notification-container {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-color: #f9f9f9; /* Couleur de fond pour la box */
        border-radius: 5px; /* Coins arrondis */
        max-width: 600px; /* Largeur maximale de la box */
        margin: 0 auto; /* Centrer la box */
        color: #000;
        margin-top: 10px;
    }

    .notification-option {
        display: flex;
        align-items: center;
    }

    .notification-option input[type="checkbox"] {
        margin-right: 5px;
    }

    .newsletter-container {
        display: flex;
        justify-content: left; /* Center the option */
        padding: 10px 0px;
        max-width: 400px; /* Maximum width of the box */
        margin: 0 auto; /* Center the box */
    }

    .newsletter-option {
        display: flex;
        align-items: center;
    }

    .newsletter-option input[type="checkbox"] {
        margin-right: 5px;
    }

    .btn-submit {
        background-color: #016a40;
        color: white;
        border: none;
        padding: 10px 20px;
        font-size: 1em;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s;
        margin-top: -10px;
    }

    .form-group .error-message {
        display: block;
        font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
        font-weight: normal; /* Ajustez le poids de la police si nécessaire */
        transition: font-size 0.3s; /* Transition pour une animation douce */
        color: #FFFC00; /* Couleur du texte rouge */
        font-style: italic; /* Texte en italique */
        margin-top: 5px;
        text-align: center;
    }
</style>
