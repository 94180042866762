<template>
    <Header />
    <PageHeader title="Contactez-nous" />
    <div class="contact-page">
        <img src="@/assets/images/ewallet_36.png" alt="Mockup" class="mockup-image">
        <p class="m-text">Nous sommes là pour vous aider. N'hésitez pas à nous contacter via les informations ci-dessous ou en utilisant le formulaire de contact.</p>
        <div class="block-adress-physic">
            <h4><i class="fas fa-location-dot icon-bg"></i>Adresse Physique</h4>
            <p class="m-text">
                Kòb Dirèk<br/> 
                <span class="md-text">
                    #26, Impasse Bethany, Petite Place Cazeau,<br/>
                    Delmas, Port-au-Prince, Ouest, Haïti
                </span>
            </p>
        </div>

        <div class="contact-blocks">
            <div class="block-adress-email">
                <h4><i class="fas fa-envelope icon-bg"></i>Email Générale</h4>
                <p class="md-text">info@kobdirek.com</p>
            </div>
            <div class="block-adress-phone">
                <h4><i class="fas fa-phone icon-bg"></i>Téléphone</h4>
                <p class="md-text">(509) 40409077</p>
            </div>
        </div>

        <img src="@/assets/images/ewallet_30.png" alt="Mockup" class="mockup-image">

        <h4>Formulaire de Contact</h4>
        <div class="form-container">
            <form @submit.prevent="handleSubmit" novalidate>
            <div class="form-group">
                <label for="fullName"><i class="fas fa-user icon-bg"></i> Nom complet:</label>
                <input type="text" id="fullName" v-model="form.fullName" @input="handleFullNameInput"  required>
                <span v-if="fullNameError" class="error-message">{{ fullNameError }}</span>
            </div>

            <div class="form-group">
                <label for="email"><i class="fas fa-envelope icon-bg"></i> Adresse email:</label>
                <input type="email" id="email" v-model="form.email" @input="handleEmailInput" required>
                <span v-if="emailError" class="error-message">{{ emailError }}</span>
            </div>

            <div class="form-group">
                <label for="phone"><i class="fas fa-phone icon-bg"></i> Numéro de téléphone:</label>
                <input type="tel" id="phone" v-model="form.phone" @input="handlePhoneInput" >
                <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
            </div>

            <div class="form-group">
                <label for="subject"><i class="fas fa-question-circle icon-bg"></i> Sujet:</label>
                <input type="text" id="subject" v-model="form.subject" @input="handleSubjectInput" required>
                <span v-if="subjectError" class="error-message">{{ subjectError }}</span>
            </div>

            <div class="form-group">
                <label for="message"><i class="fas fa-message icon-bg"></i> Message:</label>
                <textarea id="message" v-model="form.message" rows="5" @input="handleMessageError" required></textarea>
                <span v-if="messageError" class="error-message">{{ messageError }}</span>
            </div>

            <button type="submit" :disabled="loading">
                <span v-if="loading">
                    Envoie en cours... <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else>
                    <i class="fas fa-arrow-right"></i> Envoyer maintenant
                </span>
            </button>

            <div class="form-group">
                <span v-if="requestError" class="error-message">{{ requestError }}</span>
            </div>

            </form>
        </div>

        <h4>Support Client</h4>
        <div class="contact-blocks">
            <div class="block-adress-email">
                <h4><i class="fas fa-envelope icon-bg"></i>Email de Support</h4>
                <p class="md-text">support@kobdirek.com</p>
            </div>
            <div class="block-adress-phone">
                <h4><i class="fas fa-mail-bulk icon-bg"></i>Support médias</h4>
                <p class="md-text">support.social@kobdirek.com</p>
            </div>
        </div>

        <img src="@/assets/images/ewallet_15.png" alt="Mockup" class="mockup-image">

        <div class="block-links">
            <h4><i class="fas fa-link icon-bg"></i>Liens Utiles</h4>
            <ul class="custom-list">
                <li><router-link to="/faq" class="link">FAQ</router-link></li>
                <li><router-link to="/terms-conditions" class="link">Conditions Générales d'Utilisation</router-link></li>
                <li><router-link to="/privacy-policy" class="link">Politique de Confidentialité</router-link></li>
            </ul>
        </div>

        <h4>Suivez-nous sur:</h4>
        <div class="block-socials">
            <a href="#" style="background-color: #25D366; padding: 8px;">
                <i class="fab fa-whatsapp" style="color: #FFFFFF;"></i>
            </a>
            <a href="https://www.facebook.com/kobdirek" style="background-color: #3b5998; padding: 8px;">
                <i class="fab fa-facebook" style="color: #FFFFFF;"></i>
            </a>
            <a href="https://x.com/kobdirek" style="background-color: #1DA1F2; padding: 8px;">
                <i class="fab fa-twitter" style="color: #FFFFFF;"></i>
            </a>
            <a href="https://www.instagram.com/kobdirek" style="background-color: #C13584; padding: 8px;">
                <i class="fab fa-instagram" style="color: #FFFFFF;"></i>
            </a>
            <a href="https://www.tiktok.com/@kobdirekht" style="background-color: #1C9CEA; padding: 8px;">
                <i class="fab fa-tiktok" style="color: #FFFFFF;"></i>
            </a>
            <a href="#" style="background-color: #FF0000; padding: 8px;">
                <i class="fab fa-youtube" style="color: #FFFFFF;"></i>
            </a>
            <a href="https://www.snapchat.com/add/kobdirekht" style="background-color: #FFFC00; padding: 8px;">
                <i class="fab fa-snapchat" style="color: #000000;"></i>
            </a>
            <a href="https://t.me/kobdirek" style="background-color: #0088cc; padding: 8px;">
                <i class="fab fa-telegram" style="color: #FFFFFF;"></i>
            </a>
        </div>
    </div>

    <Footer />
</template>
  
<script>
  //import axios from 'axios';
  import Header from './shared/HeaderPage.vue';
  import Footer from './shared/FooterPage.vue';
  import PageHeader from './shared/PageHeader.vue';

  import axios from 'axios';
  import { SERVER_IP } from '../config';

  import { validateFullName, validateEmail, validatePhone, validateSubjectContact } from '@/utils/validationUtils';

  export default {
    name: 'ContactPage',
    components: {
      Header,
      PageHeader,
      Footer
    },
    
    data() {
        return {
        form: {
            fullName: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
        },
        fullNameError: '', // Variable pour le message d'erreur du username
        emailError: '', // Variable pour le message d'erreur du password
        phoneError: '',
        subjectError: '',
        messageError: '',
        requestError: '',
        loading: false,
        };
    },

    methods: {
        
        handleFullNameInput() {
            this.fullNameError = ""; // Masquez le message d'erreur
            this.requestError = "";
        },
        handleEmailInput() {
            this.emailError = ""; // Masquez le message d'erreur
            this.requestError = "";
        },
        handlePhoneInput() {
            this.phoneError = ""; // Masquez le message d'erreur
            this.requestError = "";
        },
        handleSubjectInput() {
            this.subjectError = "";
            this.requestError = "";
        },
        handleMessageError(){
            this.messageError = "";
            this.requestError = "";
        },

        resetForm() {
            this.form.fullName = '';
            this.form.email = '';
            this.form.phone = '';
            this.form.subject = '';
            this.form.message ='';
        },

        async handleSubmit() {
            this.loading = true; // Début du chargement

            let validationResult = validateFullName(this.form.fullName);
            if(!validationResult.isValid){
                this.fullNameError = validationResult.errorMessage;
                this.scrollToError('fullName');
                return;
            }

            validationResult = validateEmail(this.form.email);
            if(!validationResult.isValid){
                this.emailError = validationResult.errorMessage;
                this.scrollToError('email');
                return;
            }

            validationResult = validatePhone(this.form.phone);
            if(!validationResult.isValid && this.form.phone !== ""){
                this.phoneError = validationResult.errorMessage;
                this.scrollToError('phone');
                return;
            }

            validationResult = validateSubjectContact(this.form.subject);
            if(!validationResult.isValid){
                this.subjectError = validationResult.errorMessage;
                this.scrollToError('subject');
                return;
            }

            if(!this.form.message) {
                this.messageError = "Il faut écrire un message.";
                this.scrollToError('message');
                return;
            }
            
            const url = `${SERVER_IP}/request-contact`;
            try {
                const response = await axios.post(url, { 
                    fullName: this.form.fullName,
                    email: this.form.email,
                    phone: this.form.phone,
                    subject: this.form.subject,
                    message: this.form.message
                });
                if (response.data.success){
                    this.requestError = "Votre message a été soumis avec succès.";
                    this.resetForm();
                }
            } catch (error) {
                this.loading = false;
                this.requestError = "Une erreur est survenue lors de la soumission.";
            } finally {
                this.loading = false;
            }


        },

        scrollToError(field) {
            const element = document.getElementById(field);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            this.loading = false;
        },

    }

  };
</script>
  
<style scoped>

.contact-page {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: -20px;
  color: #FFFFFF;
  margin-bottom:80px;
}

.mockup-image {
  width: 150px;
  height: auto;
  margin: 2px 0;
}

.contact-page .m-text{
    text-align: justify;
}

h4{
    text-align: left;
    font-weight: normal;
    font-size: 1.2rem;
    margin-bottom: 2px;
}
.md-text{
    text-align: justify;
    font-size: 0.85rem;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

.contact-blocks {
  display: flex;
  justify-content: space-between; /* Aligner les blocs avec de l'espace entre eux */
  gap: 20px; /* Espace entre les blocs */
}

.contact-blocks > div {
  flex: 1; /* Chaque bloc prendra un espace égal */
  padding: 10px;
  padding-left: 0px;
}

.block-adress-email, .block-adress-phone {
  /*background-color: #f8f8f8; /* Couleur de fond pour distinguer les blocs */
  border-radius: 8px; /* Coins arrondis */
  padding: 10px;
}

.form-container {
  padding: 10px 0px;
  /*background-color: #f8f8f8; /* Couleur de fond pour le formulaire */
  border-radius: 8px; /* Coins arrondis */
  max-width: 400px; /* Largeur maximale pour le formulaire */
  margin: 0 auto; /* Centrer le formulaire horizontalement */
}

.form-group {
  margin-bottom: 15px; /* Espacement entre les champs du formulaire */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.form-group input,
.form-group textarea {
  padding: 0.5em 0;
  font-size: 1em;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}
.form-group input:focus,
.form-group textarea:focus{
  outline: none;
  border-bottom: 2px solid #016A40; /* Couleur de focus verte */
}

button {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 10px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
}

button:hover {
  background-color: #005934; /* Changement de couleur au survol */
}
button:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

.custom-list {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
}

.custom-list li:before {
    content: "»";
    margin-right: 5px;
    color: #FFFFFF; /* Couleur du chevron, ajustez selon vos besoins */
}
.custom-list a{
    text-decoration: none;
    color: #178FF5;
}
.custom-list a:hover{
    color: #106BB4;
}
.custom-list li{
    margin: 5px 0px;
}

.block-socials{
    display: flex; 
    justify-content: space-between;
    margin:10px 0px;
    margin-bottom: 60px;
}
.block-socials a{
    border-radius: 10px;
}

.form-group .error-message {
  display: block;
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  margin-top: 5px;
  text-align: left;
}

</style>
  