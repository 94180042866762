<!-- App.vue -->

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
  /*
  body{
    /*background-color: #000103;
  }*/
</style>