<template>
  <Header />

  <div class="home-page">
    <div class="profile-container">
      <div class="profile-left" @click="goToProfile">
        <span v-if="userInfo">
            <img :src="getImage(userInfo.image)" alt="Profile Image" class="profile-img">
        </span>
        
        <div class="user-info"  v-if="userInfo">
          <p class="username">{{ userInfo.fullname }}</p>
          <p class="account-type">{{ userInfo.accountType }}</p>
        </div>
      </div>
      <div class="profile-right" v-if="userInfo">
        <p class="balance-htg">{{ formatCurrency(userInfo.balances.HTG, 'HTG', 'fr-HT') }}</p>
        <p class="balance-usd">{{ formatCurrency(userInfo.balances.USD, 'USD', 'en-US') }}</p>
      </div>
      <div class="loading-page" v-if="!userInfo">
        <p><i class="fas fa-spinner fa-spin"></i></p>
      </div>
    </div>

    <div class="button-container">
      <!-- Premier bouton: Dépôts / Retraits / Transfert -->
      <button class="custom-button first-button" @click="showModal = true">
        <i class="fas fa-exchange-alt"></i>
        <div class="button-text">
          <p>Depôts</p>
          <p>Retraits</p>
          <p>Transfert</p>
        </div>
      </button>

      <!-- Deuxième bouton: NatCash/Moncash, Internet, Minutes -->
      <button class="custom-button second-button" @click="showModalInter = true">
        <i class="fas fa-mobile-alt"></i>
        <div class="button-text">
          <p>NatCash/Moncash</p>
          <p>Internet</p>
          <p>Minutes</p>
        </div>
      </button>
    </div>

    <div id="button-group-2">
      <!-- Premier bouton: Créer Reçu -->
      <button id="create-receipt" class="custom-button">
        <i class="fas fa-receipt"></i>
        <p>Créer Reçu</p>
      </button>

      <!-- Deuxième bouton: Taux du Jour -->
      <button id="exchange-rate" class="custom-button">
        <i class="fas fa-chart-line"></i>
        <p>Taux du Jour</p>
      </button>

      <!-- Troisième bouton: Épargne/Sol -->
      <button id="savings" class="custom-button">
        <i class="fas fa-piggy-bank"></i>
        <p>Épargne/Sol</p>
      </button>
    </div>

    <button class="button-game">
      <i class="fas fa-gamepad"></i>
      Appuyez pour jouer maintenant et découvrir si vous êtes notre prochain grand gagnant !
    </button>

    <div id="button-group-3">
      <!-- Premier bouton: Créer Reçu -->
      <button id="create-gift" class="custom-button">
        <i class="fas fa-gift"></i>
        <p>Konbit</p>
      </button>

      <!-- Deuxième bouton: Taux du Jour -->
      <button id="factures" class="custom-button">
        <i class="fas fa-file-invoice"></i>
        <p>Factures</p>
      </button>

      <!-- Troisième bouton: Épargne/Sol -->
      <button id="tips-tools" class="custom-button">
        <i class="fas fa-coins"></i>
        <p>Tips</p>
      </button>
    </div>

    <div v-if="showModal" class="modal-overlay" @click.self="showModal = false">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Basic Transaction</h3>
        </div>
        <div class="modal-body">
          <button class="modal-button" @click="loadDeposit">
            <i class="fas fa-download"></i> Dépot
          </button>
          <button class="modal-button" @click="loadTransfer">
            <i class="fas fa-exchange-alt"></i> Transfert
          </button>
          <button class="modal-button" @click="loadWithdraw">
            <i class="fas fa-upload"></i> Retrait
          </button>
        </div>
      </div>
    </div>

    <div v-if="showModalInter" class="modal-inter-overlay" @click.self="showModalInter = false">
      <div class="modal-inter-content">
        <div class="modal-inter-header">
          <h3>Interopérabilité</h3>
        </div>
        <div class="modal-inter-body">
          <div class="modal-inter-group">
            <button class="modal-inter-button">
              <i class="fas fa-exchange-alt"></i> MonCash/NatCash
            </button>
            <button class="modal-inter-button">
              <i class="fas fa-wifi"></i> Plan Digicel
            </button>
            <button class="modal-inter-button">
              <i class="fas fa-mobile-alt"></i> Recharge Digicel
            </button>
          </div>
          <div class="modal-inter-group">
            <button class="modal-inter-button">
              <i class="fas fa-exchange-alt"></i> NatCash/MonCash
            </button>
            <button class="modal-inter-button">
              <i class="fas fa-wifi"></i> Plan Natcom
            </button>
            <button class="modal-inter-button">
              <i class="fas fa-mobile-alt"></i> Recharge Natcom
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>

  <Footer />
</template>

<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';

import axios from 'axios';
import { SERVER_IP } from '@/config';
import { formatCurrency, checkAuth } from '@/utils/validationUtils';

export default {
  name: 'HomePage',
  components: {
    Header,
    Footer
  },
  created(){
    checkAuth();
  },
  mounted() {
    this.fetchUserInfo();
  },

  data() {
    return {
      userProfileImage: 'path_to_profile_image.jpg', // Remplacez par le chemin réel de l'image
      userInfo: null,
      showModal: false,
      showModalInter: false,
    };
  },

  methods: {
    goToProfile() {
      this.$router.push('/profile');
    },
    loadDeposit(){
      this.$router.push('/depot');
    },
    loadTransfer(){
      this.$router.push('/transfer');
    },
    loadWithdraw(){
      this.$router.push('/retrait');
    },
    getImage(imagePath) {
      // Si imagePath est une URL complète, la retourner directement
      if (imagePath.startsWith('http')) {
        return imagePath;
      }
      // Si imagePath est un chemin relatif, le compléter pour retourner l'URL complète
      return require(`@/assets/images/${imagePath}`);
    },
    formatCurrency(amount, currency, locale) {
      return formatCurrency(amount, currency, locale);
    },

    async fetchUserInfo() {
        try {
            const userId = checkAuth(); 
            const url = `${SERVER_IP}/userRequest/getuser-info/${userId}`;
            const response = await axios.get(url);
            this.userInfo = response.data;
        } catch (error) {
            console.error('Erreur lors de la récupération des données utilisateur:', error);
        }
    },
  },

};
</script>

<style scoped>
.home-page {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: -20px;
  color: #FFFFFF;
  margin-bottom: 80px;
  /* Ajout d'un padding pour éviter que le contenu touche les bords */
  padding: 10px;
}
.loading-page{
  color: #000;
  font-size: 1.2rem;
  width: 100%;
}

.profile-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 0.8rem;
  width: 100%;
  box-sizing: border-box; /* Pour inclure le padding dans la largeur */
  padding: 5px;
  padding-right: 20px;
  margin-top: 20px;
}

.profile-left {
  display: flex;
  align-items: center;
}

.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: bold;
  color: black;
  margin: 0;
  text-align: left;
}

.account-type {
  font-style: italic;
  font-size: 0.6rem;
  color: gray;
  margin: 0;
  text-align: left;
}

.profile-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}

.balance-htg {
  color: green;
  font-weight: bold;
  margin: 0;
  margin-bottom: 2.5px;
}

.balance-usd {
  color: blue;
  font-weight: bold;
  margin: 0;
  margin-top: 2.5px;
}

/* BUTTON MENU */
.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Ajout d'un espace entre les boutons */
  margin-top: 10px;
}

.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  border: none;
  padding: 10px;
  color: #016A40;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.custom-button i {
  margin-right: 10px;
  font-size: 2rem; /* Taille de l'icône augmentée */
}

.custom-button .button-text p {
  margin: 0;
  color: black;
  font-size: 0.8rem; /* Taille de police ajustée pour le texte */
  font-weight: normal; /* Texte non gras */
  text-align: left
}

.first-button {
  width: 40%;
}

.second-button {
  width: 60%;
}

/* BUTTTON MENU 2 */

#button-group-2 {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Ajout d'un espace égal entre les boutons */
  width: 100%;
  margin-top: 10px;
}

#button-group-2 .custom-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px; /* Coins arrondis à 5px */
  border: none;
  padding: 10px;
  color: #016A40;
  font-size: 1rem;
  cursor: pointer;
  width: 32%; /* Chaque bouton prend environ un tiers de la largeur disponible */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

#button-group-2 .custom-button i {
  font-size: 2rem; /* Taille de l'icône */
  margin-bottom: 5px;
}

#button-group-2 .custom-button p {
  margin: 0;
  color: black;
  font-size: 0.8rem; /* Taille de police ajustée pour le texte */
}

/* BUTTTON MENU 2 */

#button-group-3 {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Ajout d'un espace égal entre les boutons */
  width: 100%;
  margin-top: 10px;
}

#button-group-3 .custom-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px; /* Coins arrondis à 5px */
  border: none;
  padding: 10px;
  color: #016A40;
  font-size: 1rem;
  cursor: pointer;
  width: 32%; /* Chaque bouton prend environ un tiers de la largeur disponible */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

#button-group-3 .custom-button i {
  font-size: 2rem; /* Taille de l'icône */
  margin-bottom: 5px;
}

#button-group-3 .custom-button p {
  margin: 0;
  color: black;
  font-size: 0.8rem; /* Taille de police ajustée pour le texte */
}

/* BUTTON GAME  */

.button-game {
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent;
  border: 1px solid white;
  color: white;
  font-size: 0.8rem;
  font-weight: normal;
  padding: 0.5rem;
  cursor: pointer;
  outline: none;
  margin: 20px 0px;
  border-radius: 10px;
}

.button-game i {
  font-size: 2rem; /* Adjust the size of the icon as needed */
  margin-right: 0.5rem;
}


/* MODAL #1 (BASIC TRANSACTION) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: black;
  border: 2px solid white;
  padding: 0px 0px 20px 0px;
  width: 80%;
  max-width: 350px;
  border-radius: 5px;
}

.modal-header {
  background: white;
  color: black;
  padding: 5px 10px;
  border-bottom: 2px solid white;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding-top: 15px;
}

.modal-button {
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 30px;
  padding: 10px;
  font-size: 0.8rem;
  align-items: center;
  cursor: pointer;
  margin: 10px;
  width: 50%;
}

.modal-button i {
  margin-right: 5px;
}
.modal-button:hover{
  color: #016A40;
}


/* MODALINTER */
.modal-inter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-inter-content {
  background: black;
  border: 2px solid white;
  width: 80%;
  max-width: 350px;
  border-radius: 5px;
  padding: 0px 0px 20px 0px;
}

.modal-inter-header {
  background: white;
  color: black;
  padding: 10px;
  border-bottom: 2px solid white;
  text-align: center;
}

.modal-inter-body {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.modal-inter-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-inter-button {
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 30px;
  padding: 10px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.modal-inter-button i {
  margin-right: 5px;
}
.modal-inter-button:hover{
  color: #016A40;
}

</style>

  