<template>
    <Header />
    <div>      
      <form @submit.prevent="handleSubmit" novalidate>
        <h1>
          <i class="fas fa-arrow-left icon-back" @click="goToHome"></i>
          Nouveau compte
        </h1>

        <!-- Mockup image -->
        <img src="@/assets/images/ewallet_19.png" alt="Mockup d'interface de connexion" class="mockup-image">
  
        <div class="form-group">
          <label for="fullName">
            <i class="fas fa-user icon-bg"></i> Prénom & Nom de famille
          </label>
          <input 
            type="text" 
            id="fullName" 
            v-model="form.fullName" 
            placeholder="Prénom Nom de famille" 
            @input="handleFullNameInput" 
            required 
          />
          <span v-if="fullNameError" class="error-message">{{ fullNameError }}</span>
        </div>
  
        <div class="form-group">
          <label for="email">
            <i class="fas fa-envelope icon-bg"></i> Email
          </label>
          <input type="email" id="email" v-model="form.email" @input="handleEmailInput" @blur="checkEmail" placeholder="Email" required />
          <span v-if="emailError" class="error-message">{{ emailError }}</span>
        </div>

        <div class="form-group">
          <label for="password">
            <i class="fas fa-lock icon-bg"></i> Mot de passe
          </label>
          <div class="password-container">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              v-model="form.password"
              @input="handlePasswordInput"
              placeholder="Mot de passe"
              required
            />
            <i
              :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
              @click="togglePasswordVisibility"
              class="toggle-password-visibility"
            ></i>
          </div>
          <span v-if="passwordError" class="error-message">{{ passwordError }}</span>
        </div>


        <div class="form-group">
          <label for="confirmPassword">
            <i class="fas fa-shield-alt icon-bg"></i> Mot de passe
          </label>
          <div class="confirm-password-container">
            <input
              :type="showConfirmPassword ? 'text' : 'password'"
              id="confirmPassword"
              v-model="form.confirmPassword"
              @input="handleConfirmPasswordInput"
              placeholder="Confirmation de mot de passe"
              required
            />
            <i
              :class="showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
              @click="toggleConfirmPasswordVisibility"
              class="toggle-confirm-password-visibility"
            ></i>
          </div>
          <span v-if="confirmPasswordError" class="error-message">{{ confirmPasswordError }}</span>
        </div>
  
        <div class="form-group">
          <label for="address">
            <i class="fas fa-house icon-bg"></i> Adresse
          </label>
          <input type="text" id="address" v-model="form.address" @input="handleAddressInput" placeholder="#XXX, RUE" required />
          <span v-if="addressError" class="error-message">{{ addressError }}</span>
        </div>

        <div class="select-container">
          <div class="select-group">
            <label for="department-select"><i class="fas fa-map-marker-alt icon-bg"></i> Département</label>
            <select id="department-select" v-model="selectedDepartment" @change="updateCities">
              <option v-for="department in departments" :key="department.id" :value="department.id">
                {{ department.name }}
              </option>
            </select>
          </div>

          <div class="select-group">
            <label for="city-select"><i class="fas fa-city  icon-bg"></i> Ville</label>
            <select id="city-select" v-model="selectedCity">
              <option v-for="city in cities" :key="city">
                {{ city }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label for="phone">
            <i class="fas fa-phone icon-bg"></i> Numéro de téléphone
          </label>
          <input type="tel" id="phone" v-model="form.phone" @input="handlePhoneInput" @blur="checkPhone" pattern="509\d{8}" placeholder="509XXXXXXXX" required />
          <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
        </div>

  
        <div class="form-row">
          <div class="form-group">
            <label for="birthdate">
              <i class="fas fa-calendar-alt icon-bg"></i> Date de naissance
            </label>
            <input type="date" id="birthdate" v-model="form.birthdate" @input="handleBirthdateInput" placeholder="Date de naissance" required />
            <span v-if="birthdateError" class="error-message">{{ birthdateError }}</span>
          </div>
  
          <div class="form-group">
            <label for="gender">
              <i class="fas fa-venus-mars icon-bg"></i> Sexe
            </label>
            <select id="gender" v-model="form.gender" required>
              <option value="M">M</option>
              <option value="F">F</option>
              <option value="Autre">Autre</option>
            </select>
          </div>
        </div>
  
        <div class="form-group">
          <label for="terms" class="inline-block">
            <input type="checkbox" id="terms" v-model="form.terms" class="checkbox special-checkbox" @change="handleTermsChange" required />
            J'accepte les <router-link to="/terms-conditions" class="link">Conditions d'Utilisation</router-link> et la <router-link to="/privacy-policy" class="link">Politique de Confidentialité</router-link> de la plateforme
          </label>
          <span v-if="!form.terms && termsError" class="error-message">{{ termsError }}</span>
        </div>
  
        <div class="g-recaptcha" data-sitekey="6LfY4eopAAAAAPvwxj1A4Ju-9nPqQco97opBSCJf"></div>
  
        <button type="submit" class="btn-submit" :disabled="loading">
          <span v-if="loading">
            Création en cours... <i class="fas fa-spinner fa-spin"></i>
          </span>
          <span v-else>
            Créer un compte <i class="fas fa-user-plus"></i>
          </span>
        </button>

      </form>
    </div>
</template>


<script>
import axios from 'axios';
import Header from '../shared/HeaderPage.vue';
import { SERVER_IP } from '../../config';

export default {
  name: "RegisterForm",
  components: {
    Header
  },

  data() {
    return {
      form: {
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        address: "",
        phone: "",
        birthdate: "",
        gender: "M", // Définir "Masculin" comme valeur par défaut
        terms: false,
        loading: false,
      },
      
      fullNameError: "", // Ajoutez cette propriété pour suivre l'état de l'erreur
      phoneError: "",
      emailError: "", // Ajoutez cette propriété pour suivre l'état de l'erreur email
      passwordError: "", // Ajoutez cette propriété pour suivre l'état de l'erreur password
      confirmPasswordError: "", // Ajoutez cette propriété pour suivre l'état de l'erreur confirmPassword
      addressError: "", // Ajoutez cette propriété pour suivre l'état de l'erreur d'adresse
      birthdateError:"", //erreur Date de naissance
      termsError: "", 
      showPassword: false,
      showConfirmPassword: false,

      departments: [
        { id: 1, name: 'Artibonite' },
        { id: 2, name: 'Centre' },
        { id: 3, name: 'Grand\'Anse' },
        { id: 4, name: 'Nippes' },
        { id: 5, name: 'Nord' },
        { id: 6, name: 'Nord-Est' },
        { id: 7, name: 'Nord-Ouest' },
        { id: 8, name: 'Ouest' },
        { id: 9, name: 'Sud' },
        { id: 10, name: 'Sud-Est' }
      ],
      citiesByDepartment: {
        1: [
          'Gonaïves', 'Saint Marc', 'Petite-Rivière-de-l\'Artibonite', 'Gros-Morne',
          'Saint-Michel-de-l\'Attalaye', 'Desdunes', 'Dessalines', 'L’Estère', 'Verrettes'
        ],
        2: [
          'Hinche', 'Mirebalais', 'Thomassique', 'Maïssade', 'Thomonde', 'Lascahobas'
        ],
        3: [
          'Jérémie', 'Anse-d\'Ainault'
        ],
        4: [
          'Miragoâne'
        ],
        5: [
          'Cap-Haïtien', 'Limbé', 'Limonade', 'Plaisance', 'Grande Rivière du Nord',
          'Saint-Raphaël', 'Pignon', 'Terrier-Rouge', 'Port-Margot', 'Dondon', 'Plaine-du-Nord',
          'Dame-Marie', 'Acul-du-Nord'
        ],
        6: [
          'Ouanaminthe', 'Trou-du-Nord', 'Fort-Liberté'
        ],
        7: [
          'Port-de-Paix', 'Saint-Louis-du-Nord', 'Jean Rabel'
        ],
        8: [
          'Port-au-Prince', 'Carrefour', 'Delmas', 'Pétionville', 'Cité Soleil',
          'Croix-des-Bouquets', 'Petit-Goâve', 'Léogâne', 'Tabarre', 'Cabaret', 'Anse-à-Galets',
          'Grand-Goâve', 'Arcahaie', 'Kenscoff', 'Gressier', 'Fonds-Verrettes', 'Thomazeau',
          'Belladère', 'Ganthier'
        ],
        9: [
          'Les Cayes', 'Aquin'
        ],
        10: [
          'Jacmel'
        ]
      },
      selectedDepartment: 8,
      selectedCity: '',
      cities: [],
      conditiontest: 1,
    };
  },

  mounted() {
    this.updateCities(); // Mettre à jour les villes lors du montage
  },

  computed: {
    formattedPhone: {
      get() {
        // Assurez-vous que l'affichage commence avec '509'
        return this.form.phone ? `509${this.form.phone.replace(/^509/, '')}` : '509';
      },
      set(value) {
        // Enlevez le préfixe pour stocker uniquement le numéro sans le préfixe dans le modèle
        this.form.phone = value.startsWith('509') ? value.substring(3) : value;
      }
    }
  },
  
  methods: {
    handleFullNameInput() {
      this.fullNameError = ""; // Masquez le message d'erreur
    },

    handleFocus(event) {
      // Ajoute '509' par défaut si ce n'est pas déjà présent
      if (!this.form.phone.startsWith('509')) {
        const cursorPos = event.target.selectionStart; // Position du curseur
        this.formattedPhone = `509${this.form.phone}`; // Ajoute '509' au début
        this.$nextTick(() => {
          event.target.setSelectionRange(cursorPos + 3, cursorPos + 3); // Positionne le curseur à la fin
        });
      }
    },
    handleBlur() {
      // Ajoute '509' au début si ce n'est pas déjà présent
      if (!this.formattedPhone.startsWith('509')) {
        this.formattedPhone = `509${this.formattedPhone}`;
      }
    },
    handlePhoneInput() {
      this.phoneError = "";
      //this.form.phone = '509';
    },



    handleEmailInput() {
      this.emailError = ""; // Masquez le message d'erreur
    },
    handlePasswordInput() {
      this.passwordError = ""; // Masquez le message d'erreur
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    handleConfirmPasswordInput() {
      this.confirmPasswordError = ""; // Masquez le message d'erreur
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    handleAddressInput() {
      this.addressError = ""; // Réinitialise l'erreur d'adresse lorsqu'un utilisateur commence à saisir
    },
    handleBirthdateInput() {
      // Réinitialiser le message d'erreur lorsque l'utilisateur commence à saisir
      this.birthdateError = "";
    },
    handleTermsChange() {
      if (this.form.terms) {
        this.termsError = ""; // Effacer le message d'erreur lorsque la case est cochée
      }
    },

    updateCities() {
      this.cities = this.citiesByDepartment[this.selectedDepartment] || [];
      this.selectedCity = this.cities[0] || '';
    },
    getDepartmentNameById(departmentId) {
      const department = this.departments.find(dept => dept.id === departmentId);
      return department ? department.name : 'Département inconnu';
    },

    async checkEmail() {
      try {
        const url = `${SERVER_IP}/check-email`;
        const response = await axios.post(url, { email: this.form.email });
        if (response.data.error) {
          this.emailError = response.data.error;
          return false;
        } else {
          this.emailError = '';
          return true;
        }
      } catch (error) {
        console.error('Error checking email:', error);
        this.emailError = 'An error occurred. Please try again later.';
        return false;
      }
    },

    async checkPhone() {

      const phone = this.form.phone.trim();
      if (phone && !phone.startsWith('509')) {
        this.form.phone = '509' + phone;
      } else {
        this.form.phone = phone;
      }

      try {
        const url = `${SERVER_IP}/check-phone`;
        const response = await axios.post(url, { phone: this.form.phone });
        if (response.data.error) {
          this.phoneError = response.data.error;
          return false;
        } else {
          this.phoneError = '';
          return true;
        }
      } catch (error) {
        console.error('Error checking phone:', error);
        this.phoneError = 'Une erreur est survenue.';
        return false;
      }
    },


    async handleSubmit() {          
      this.loading = true; // Début du chargement

      if (!this.validateAddress()) {
        this.scrollToError('address');
        return;
      }

      if (!this.validateFullName()) {
        this.scrollToError('fullName');
        return;
      }

      if (!this.validateEmail()) {
        this.scrollToError('email');
        return;
      }

      if (!this.validatePassword()) {
        this.scrollToError('password');
        return;
      }

      if (!this.validateConfirmPassword()) {
        this.scrollToError('confirmPassword');
        return;
      }

      if (!this.validateAddress()) {
        this.scrollToError('address');
        return;
      }

      if (!this.validatePhone()) {
        this.scrollToError('phone');
        return;
      }

      if (!this.validateBirthdate()) {
        this.scrollToError('birthdate');
        return;
      }

      if (!this.validateGender()) {
        this.scrollToError('gender');
        return;
      }

      if (!this.form.terms) {
        this.termsError = "Veuillez accepter les Conditions d'Utilisation et la Politique de Confidentialité.";
        this.vibrate(); // Appeler la fonction de vibration
        this.loading = false;
        return;
      }

      // Obtenir la réponse reCAPTCHA
      /*const recaptchaResponse = grecaptcha.getResponse();

      // Vérifier si le reCAPTCHA a été vérifié
      if (!recaptchaResponse) {
        alert("Veuillez vérifier le reCAPTCHA.");
        return;
      }*/

      // Si toutes les validations passent, soumettez le formulaire
      //this.submitForm();
      // Vérifiez les erreurs après validation
      if (
        this.fullNameError || this.emailError || this.passwordError ||
        this.confirmPasswordError || this.addressError || this.phoneError ||
        this.birthdateError || this.termsError
      ) {
        return;
      }

      // Vérification de l'email
      const emailCheck = await this.checkEmail();
      if (!emailCheck) {
        this.loading = false;
        return;
      }

      // Vérification du téléphone
      const phoneCheck = await this.checkPhone();
      if (!phoneCheck) {
        this.loading = false;
        return;
      }

      // Si toutes les validations passent, soumettre le formulaire
      try {
        const url = `${SERVER_IP}/register`;
        const departmentName = this.getDepartmentNameById(this.selectedDepartment);
        const reformulatedAddress = `${this.form.address}, ${this.selectedCity}, ${departmentName}`;
        const response = await axios.post(url, {
          fullName: this.form.fullName,
          email: this.form.email,
          password: this.form.password,
          address: reformulatedAddress,
          phone: this.form.phone,
          birthdate: this.form.birthdate,
          gender: this.form.gender,
        });

        if (response.data.success) {
          this.$router.push('/success-register'); // Redirect to success page
        } else {
          alert('Erreur lors de l\'inscription.');
        }
      } catch (error) {
        alert('Erreur lors de l\'inscription.');
      } finally {
        this.loading = false; // Fin du chargement
      }
    },

    goToHome() {
      // Redirect to home page
      this.$router.push('/');
    },

    validateFullName() {
      if (!this.form.fullName) {
        this.fullNameError = "Veuillez entrer votre prénom et nom de famille.";
        return false;
      }

      const fullNameWords = this.form.fullName.split(" ");
      if (fullNameWords.length < 2) {
        this.fullNameError = "Veuillez entrer au moins deux mots pour votre nom complet.";
        return false;
      }

      if (this.form.fullName.length < 6) {
        this.fullNameError = "Votre nom complet doit comporter au moins 6 caractères.";
        return false;
      }

      const fullNamePattern = /^[A-Za-z\s'-]+$/;
      if (!fullNamePattern.test(this.form.fullName)) {
        this.fullNameError = "Le nom complet ne doit contenir que des lettres, des espaces, des tirets et des apostrophes.";
        return false;
      }

      if (this.form.fullName.length > 50) {
        this.fullNameError = "Votre nom complet ne doit pas dépasser 50 caractères.";
        return false;
      }

      return true;
    },

    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.form.email) {
        this.emailError = "Veuillez entrer une adresse email.";
        return false;
      }
      if (!emailPattern.test(this.form.email)) {
        this.emailError = "Veuillez entrer une adresse email valide.";
        return false;
      }
      return true;
    },

    validatePassword() {
        const password = this.form.password;

        if (!password) {
        this.passwordError = "Le champ Mot de passe est requis.";
        return false;
        }
        if (password.length < 8) {
        this.passwordError = "Le mot de passe doit contenir au moins 8 caractères.";
        return false;
        }
        if (!/\d/.test(password)) {
        this.passwordError = "Le mot de passe doit contenir au moins un chiffre.";
        return false;
        }
        if (!/[a-zA-Z]/.test(password)) {
        this.passwordError = "Le mot de passe doit contenir au moins une lettre.";
        return false;
        }
        // Exemple de validation supplémentaire : vérification de la présence d'un caractère spécial
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        this.passwordError = "Le mot de passe doit contenir au moins un caractère spécial.";
        return false;
        }

        return true;
    },

    validateConfirmPassword() {
      if (!this.form.confirmPassword) {
        this.confirmPasswordError = "Veuillez confirmer votre mot de passe.";
        return false;
      }
      if (this.form.confirmPassword !== this.form.password) {
        this.confirmPasswordError = "Les mots de passe ne correspondent pas.";
        return false;
      }
      return true;
    },

    validateAddress() {
      // Updated pattern to allow for more flexible address formats
      const addressPattern = /^#\d+,\s*[^,]+(?:,[^,]+)?(?:,[^,]+)?$/;

      if (!this.form.address) {
        this.addressError = "Veuillez entrer une adresse.";
        return false;
      }

      if (!addressPattern.test(this.form.address)) {
        this.addressError = "L'adresse doit être au format : #XX, RUE...";
        return false;
      }

      return true;
    },


    validatePhone() {
      const phone = this.form.phone.trim();
      const phonePattern = /^509(31|36|37|38|28|34|39|29|46|48|44|47|49|22|32|33|35|42|43|40|41|55)\d{6}$/;
      
      if (!phone) {
        this.phoneError = "Le champ Numéro de téléphone est requis.";
        return false;
      }

      if (!phonePattern.test(phone)) {
        this.phoneError = "Le numéro de téléphone doit commencer par '509' suivi de 8 chiffres valides.";
        return false;
      }

      this.phoneError = null; // Reset the error message if validation passes
      return true;
    },

    validateBirthdate() {
      if (!this.form.birthdate) {
        this.birthdateError = "Veuillez entrer votre date de naissance.";
        return false;
      }
      const today = new Date();
      const birthdate = new Date(this.form.birthdate);
      let age = today.getFullYear() - birthdate.getFullYear();
      const monthDifference = today.getMonth() - birthdate.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthdate.getDate())
      ) {
        age--;
      }
      if (age < 18) {
        this.birthdateError = "Vous devez avoir au moins 18 ans.";
        return false;
      }
      return true;
    },

    validateGender() {
      if (!this.form.gender) {
        this.genderError = "Veuillez sélectionner votre sexe.";
        return false;
      }
      return true;
    },

    vibrate() {
      // Vérifier si la fonction de vibration est supportée
      if (navigator.vibrate) {
        navigator.vibrate(200); // Vibration de 200 ms
      }
    },

    scrollToError(field) {
      const element = document.getElementById(field);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      this.loading = false;
    },
  }
};
</script>


<style scoped>

.mockup-image {
  max-width: 150px;
  margin: 20px auto;
  display: block;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
  background-color: #000103;
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top:-10px;
}

.form-group {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}
.form-row {
  display: flex;
  justify-content: space-between; /* Répartition égale de l'espace entre les enfants */
}

.form-row .form-group {
  width: 48%; /* Largeur égale pour chaque form-group */
}

input[type="date"],
select {
  font-size: 1.2em; /* Taille de la police fixe */
}


.form-group label {
  display: block; /* Afficher les labels en bloc */
}

label {
  margin-bottom: 0.5em;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

input,
select {
  padding: 0.5em 0;
  font-size: 1em;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}

input:focus,
select:focus {
  outline: none;
  border-bottom: 2px solid #016A40; /* Couleur de focus verte */
}

.inline-block {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
}

.checkbox {
  margin-right: 10px; /* Marge à droite pour l'espace */
}

.special-checkbox {
  width: auto; /* Définir une largeur automatique */
}

.link {
  color: #178FF5; /* Nouvelle couleur bleue */
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Style pour le fond de l'input checkbox lorsque coché */
.checkbox:checked {
  background-color: #016A40; /* Fond vert */
}

.btn-submit {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 10px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
}

.btn-submit:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-submit:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

input[type="date"]{
  font-size: 1.2em; /* Taille de la police fixe */
}

.g-recaptcha {
  max-width: 300px; /* Largeur maximale du conteneur */
  margin: auto; /* Centre horizontalement */
  padding-top: 10px; /* Espacement au-dessus du reCAPTCHA */
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
}

.icon-back {
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    margin-right: 50px;
    color: black;
    font-size: 0.5em;
    cursor: pointer;
  }
.password-container {
  position: relative;
  width: 100%;
}
.toggle-password-visibility {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgb(105, 105, 105);
}

.confirm-password-container {
  position: relative;
  width: 100%;
}
.toggle-confirm-password-visibility {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgb(105, 105, 105);
}


.select-container {
  display: flex;
  gap: 1em; /* Espace entre les éléments */
  margin-bottom: 1em;
}

.select-group {
  display: flex;
  flex-direction: column;
  width: 200px; /* Largeur fixe pour les éléments select */
}

select {
  padding: 0.5em;
  font-size: 1em;
  background-color: black;
  border: 2px solid #FFF;
  color: white;
  border-radius: 4px;
  outline: none;
}

select:focus {
  border-color: #016A40; /* Bordure plus claire lors du focus */
}

label {
  margin-bottom: 0.5em;
  color: white;
}


</style>
